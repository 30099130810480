import React from "react";
import { useDisconnect, useActiveWallet } from "thirdweb/react";

function LogOut(props) {
    const { disconnect } = useDisconnect();
    const wallet = useActiveWallet();

    return (
        <div
            className="profile-item logout"
            onClick={() => {
                if (props.logOut) {
                    props.logOut();
                }
                if (disconnect && wallet && process.env.build && (process.env.build === 'ovi' || process.env.build === 'test')) {
                    disconnect(wallet);
                }
            }}
        >
            Log Out
        </div>
    );
}

export default LogOut;