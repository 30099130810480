import React from 'react';
import {
    thirdwebClient,
    getUserName,
    getUserImg
} from '../../utils/text';
import { ConnectButton, useActiveAccount } from "thirdweb/react";

const ThirdwebButton = (props) => {
    const activeAccount = useActiveAccount();
    // console.log('activeAccount', activeAccount)
    return (
        <ConnectButton
            client={thirdwebClient}
            connectButton={{
                className: `${props.className ? props.className : ''} ${props.disabled ? 'disabled' : ''} ${!((props.onlyActiveAccount && activeAccount) || !props.onlyActiveAccount) ? "hide-thirdweb-button" : ''}`,
                label: props.text
            }}
            switchButton={{
                className: `${props.className ? props.className : ''} ${props.disabled ? 'disabled' : ''}`,
                label: props.text
            }}
            detailsButton={{
                className: props.className,
                connectedAccountName: getUserName(props.user),
                connectedAccountAvatarUrl: getUserImg(props.user)
            }}
            detailsModal={{
                connectedAccountName: getUserName(props.user),
                connectedAccountAvatarUrl: getUserImg(props.user),
                hideDisconnect: true
            }}
            connectModal={{ size: "compact" }}
            onConnect={(params) => console.log('ssssss1', params)}
            onDisconnect={(params) => console.log('ssssss2', params)}
            auth={{
                doLogin: (params) => { console.log('ssssssss-doLogin', params); return params },
                doLogout: () => { console.log('ssssssss-doLogout'); return; },
                getLoginPayload: (params) => {
                    console.log('ssssssss-getLoginPayload', params);
                    if (props.verifyToken) {
                        props.verifyToken({ ...params, type: 'thirdweb', website: process.env.website });
                    }
                    return params
                },
                isLoggedIn: (params) => { console.log('ssssssss-isLoggedIn', params); return params },
            }}
        />
    )
}

export default ThirdwebButton;