import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from './reducers/Reducer';
import { createLogger } from 'redux-logger'
import 'draft-js/dist/Draft.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import "react-datetime/css/react-datetime.css";
import './style/index.scss';
import App from './App';
import thunk from 'redux-thunk';
import appClient from './utils/AppClient';
import chatClient from './utils/ChatClient';
import fanoutClient from './utils/FanoutClient';
import firebaseClient from './utils/FirebaseClient';
import deviceManager from './utils/DeviceManager';
import Loader from './components/Widget/Loader';
import * as appActions from './actions/app_actions';
import { stringToBoolean } from './utils/text';
import klaviyoClient from "./utils/KlaviyoClient";
import { getAppStore, setAppStore } from "./utils/Context";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ThirdwebProvider } from "thirdweb/react";

const history = createBrowserHistory();
window.browserHistory = history;

history.listen(() => {
    window.scroll({
        top: 0,
        behavior: 'smooth'
    });
});

const redux_logger = createLogger({
    predicate: (getState, action) => [
        "SILENCE",
        "INCREMENT_TIMER",
        "PARTICIPANT_SPEAKING",
        "PARTICIPANT_QUALITY_UPDATED",
    ].indexOf(action.type) === -1,
    //collapsed: (getState, action) => action.type === 'FORM_CHANGE'
});

// Creating store
let middleware = [
    routerMiddleware(history), // for dispatching history actions
    thunk,
    appClient.createMiddleware(),
    chatClient.createMiddleware(),
    fanoutClient.createMiddleware(),
    firebaseClient.createMiddleware(),
    deviceManager.createMiddleware()];
if (process.env.NODE_ENV !== 'production') {
    middleware.push(redux_logger);
}
const store = createStore(
    createRootReducer(history),
    compose(
        applyMiddleware(...middleware)
    )
);

// Temp for debugging
window.store = store;
setAppStore(store)

// Set store
appClient.setStore(getAppStore());
chatClient.setStore(getAppStore());
fanoutClient.setStore(getAppStore());
firebaseClient.setStore(getAppStore());
deviceManager.setStore(getAppStore());

const root = ReactDOM.createRoot(document.getElementById('root'));

const renderApp = () => {
    root.render(
        <GoogleOAuthProvider clientId="680879813365-nem9m3i41572e6dnsn8tq7ud31j7uef7.apps.googleusercontent.com">
            <ThirdwebProvider>
                <Provider store={getAppStore()}>
                    <App history={history} />
                </Provider>
            </ThirdwebProvider>
        </GoogleOAuthProvider>
    );
}

const renderLoader = (stage) => {
    root.render(
        <div className={"bc-wrapper"}>
            <Loader seconds={10} />
        </div>
    );
}
const error = (message) => {

    root.render(
        <div>{message || 'Access not granted'}</div>
    );
}
const init = () => {
    klaviyoClient.load();
    firebaseClient.initFirebase().then((result) => {
        store.dispatch(appActions.stageLoading(appActions.APP_STAGE_APP_INIT));
        renderApp();
        if (result) {
            // Client initialization
            firebaseClient.init().then((res) => {
                if (res) {
                    console.log('Initialized app');
                    // Start render app
                    store.dispatch(appActions.stageLoading(appActions.APP_STAGE_LOADED));
                } else {
                    error('Could not load the app');
                }
            }).catch(e => {
                console.error('Could not init the app', e.message)
            });
        } else {
            error('Could not load the app');
        }
    });
}
const passwordProtected = (process.env.passwordProtected && stringToBoolean(process.env.passwordProtected));
console.log('passwordProtected', passwordProtected);
// renderLoader(appActions.APP_STAGE_FB_INIT);
store.dispatch(appActions.stageLoading(1));

if (!passwordProtected) {
    console.log('About to initialize app');
    init();
} else {
    console.log('About to verify user credentials');
    const Http = new XMLHttpRequest();
    Http.withCredentials = true;
    let apiurl = process.env.apiDimain;
    const url = `${apiurl}/auth`;

    Http.onreadystatechange = (e) => {
        console.log('onreadystatechange', e, Http.responseText, Http.status);
        if (Http.readyState === 4) {
            console.log('response', Http.response, Http.getAllResponseHeaders());
            if (Http.status == 200)
                init();
            else
                error();
        }
    }

    Http.onerror = (e) => {
        console.log('onreadystatechange', e, Http.responseText, Http.status);
        // if (Http.readyState === 4) {
        console.log('response', Http.response, Http.getAllResponseHeaders());
        error('Could not verify user credentials');
        // }
    }
    Http.open("GET", url, true);
    Http.send();
}
if (module && module.hot) {
    module.hot.accept();
}
